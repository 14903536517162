import { FC } from 'react';

import {
    Box,
    Button,
    Card,
    CardContent,
    Icons,
    Typography,
} from '@thirdspacelearning/library';

import { ContentDomainInfo } from '../../../shared';

import { LearningObjectiveCardProps } from './LearningObjectiveCardProps';

const sx = {
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 1,
        minHeight: '100px',
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
};

const SimpleLearningObjectiveCard: FC<LearningObjectiveCardProps> = ({
    learningObjective,
    onEdit,
    type,
}) => {
    return (
        <Card data-testid={`loCard-${type}`}>
            <CardContent sx={sx.content}>
                <Typography>{learningObjective.title}</Typography>

                <Box sx={sx.buttonWrapper}>
                    {onEdit && (
                        <Button
                            variant="text"
                            startIcon={<Icons.Edit />}
                            color="primary"
                            onClick={onEdit}
                        >
                            Edit
                        </Button>
                    )}
                    <ContentDomainInfo learningObjective={learningObjective} />
                </Box>
            </CardContent>
        </Card>
    );
};

export default SimpleLearningObjectiveCard;
