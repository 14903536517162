import { FC, useState } from 'react';

import {
    Box,
    Chip,
    ChipProps,
    Icons,
    Menu,
    MenuItem,
    Typography,
} from '@thirdspacelearning/library';

import { capitalise } from '../../../utils';
import { ProgrammeStatusType } from '../types';

const sx = {
    menu: {
        '& .MuiMenu-list': {
            minWidth: '150px',
        },
    },
    menuItem: (selected: boolean) => ({
        display: 'flex',
        justifyContent: 'space-between',
        bgcolor: selected ? 'primary.light' : 'initial',
    }),
};

type Props = {
    items: string[];
    onChange: (item: any) => void;
    label: string;
    color: ChipProps['color'];
    selected: ProgrammeStatusType | null;
};

const ChipMenu: FC<Props> = ({ items, onChange, label, color, selected }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleChange = (item: string) => {
        onChange(item);
        setAnchorEl(null);
    };

    return (
        <Box>
            <Chip
                label={label}
                color={color}
                variant="filled"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                onDelete={(e) => setAnchorEl(e.currentTarget)}
                deleteIcon={<Icons.ExpandMore />}
                data-testid="chip-menu"
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={(e) => setAnchorEl(null)}
                sx={sx.menu}
            >
                {items.map((item) => (
                    <MenuItem
                        key={item}
                        onClick={(e) => handleChange(item)}
                        data-testid={`chip-${item}`}
                        sx={sx.menuItem(selected === item)}
                    >
                        <Typography variant="body2">
                            {capitalise(item)}
                        </Typography>
                        {selected === item && (
                            <Icons.CheckRounded color="primary" />
                        )}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default ChipMenu;
