import { FC } from 'react';

import { Box, Grid, Icons } from '@thirdspacelearning/library';

import { ContentMapNode } from '../../../types';

import {
    ButtonCard,
    ComplexLearningObjectiveCard,
    SimpleLearningObjectiveCard,
} from './MapCards';

const sx = {
    gridItem: {
        flexBasis: '30.5%',
        flexShrink: 1,
        position: 'relative',
        marginLeft: '15px',
    },
    positionItem: {
        paddingLeft: 0,
    },
    leftArrow: {
        position: 'absolute',
        top: 'calc(50% - 17.5px)',
        right: -17.5,
    },
    downArrow: {
        position: 'absolute',
        right: 'calc(50% - 17.5px)',
        bottom: -17.5,
    },
};

interface SupportRowProps {
    node: ContentMapNode;
    setNodeId: (nodeId: number) => void;
    setEditingNode: (
        node:
            | ContentMapNode
            | { id: null; learning_objective: Record<string, never> }
            | null,
    ) => void;
    currentView: 'simple' | 'complex';
    isInDifferentYearGroup: boolean;
    isInDfferentStrand: boolean;
}

const reversePositionSort = (a: ContentMapNode, b: ContentMapNode) =>
    b.position - a.position;

const SupportRow: FC<SupportRowProps> = ({
    node,
    setEditingNode,
    setNodeId,
    currentView,
    isInDifferentYearGroup,
    isInDfferentStrand,
}) => {
    const supportNodes = node.supports?.slice().sort(reversePositionSort);

    const LearningObjectiveCard =
        currentView === 'simple'
            ? SimpleLearningObjectiveCard
            : ComplexLearningObjectiveCard;

    const onClickButtonCard = () => {
        setEditingNode({
            id: null,
            learning_objective: {},
        });
        setNodeId(node.id);
    };

    const onEditCard = () => {
        setEditingNode(node);
    };

    return (
        <>
            {(!supportNodes || supportNodes.length < 2) && (
                <Grid item xs={4} sx={sx.gridItem}>
                    <Box marginRight={2}>
                        <ButtonCard
                            label="Link objective"
                            onClick={onClickButtonCard}
                            variant="text"
                            type="support"
                        />
                    </Box>
                    <Icons.ChevronLeft
                        color="primary"
                        fontSize="large"
                        sx={sx.leftArrow}
                    />
                </Grid>
            )}
            {supportNodes &&
                supportNodes.map((support, _index) => (
                    <Grid item xs={4} sx={sx.gridItem} key={support.id}>
                        <Box marginRight={2}>
                            <LearningObjectiveCard
                                learningObjective={support.learning_objective}
                                onEdit={() => setEditingNode(support)}
                                isInDfferentStrand={isInDfferentStrand}
                                isInDifferentYearGroup={isInDifferentYearGroup}
                                type="support"
                            />
                        </Box>
                        <Box sx={sx.leftArrow}>
                            <Icons.ChevronLeft
                                color="primary"
                                fontSize="large"
                            />
                        </Box>
                    </Grid>
                ))}
            <Grid item xs={4} sx={sx.gridItem}>
                <Box marginBottom={3}>
                    <LearningObjectiveCard
                        learningObjective={node.learning_objective}
                        onEdit={onEditCard}
                        isInDfferentStrand={isInDfferentStrand}
                        isInDifferentYearGroup={isInDifferentYearGroup}
                        type="node"
                    />
                </Box>
                <Box sx={sx.downArrow}>
                    <Icons.KeyboardArrowDown color="primary" fontSize="large" />
                </Box>
            </Grid>
        </>
    );
};

export default SupportRow;
