import { FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import {
    Box,
    Chip,
    ConfirmationDialog,
    DataGrid,
    GridColDef,
    GridSortModel,
    IconButton,
    Icons,
    LoadingButton,
    SectionMessage,
    Tooltip,
    TSLLink,
    Typography,
} from '@thirdspacelearning/library';

import { SlidesPreviewDialog, ViewType } from '../../../learning-content';
import { LOsType, ProgrammeStatusType } from '../../types';

import { yearGroupSortComparator } from '../../utils';
import { LearningObjective } from '../../../../types';

import {
    CurriculumQueryKeys,
    deleteLearningObjectives,
    getLearningObjective,
} from '@api';

const sx = {
    icon: { width: '10px', height: '10px', mr: 0.5 },
};

type Props = {
    los: LOsType[];
    programmeId: number;
    programmeStatus: ProgrammeStatusType | null;
};

const ProgrammeTable: FC<Props> = ({ los, programmeStatus, programmeId }) => {
    const queryClient = useQueryClient();

    const [showSlidesPreview, setShowSlidesPreview] = useState(false);
    const [loId, setLoId] = useState<number | undefined>();

    const [showDeleteLO, setShowDeleteLO] = useState(false);
    const [deleteLoId, setDeleteLOId] = useState<number | undefined>();

    const { data: learningObjective, isFetching } = useQuery<LearningObjective>(
        {
            queryKey: [`${CurriculumQueryKeys.LO}-${loId}`],
            queryFn: () => getLearningObjective(loId),
            enabled: !!loId,
            retry: 1,
            refetchOnWindowFocus: false,
        },
    );

    const { mutate: deleteLOs, isPending: isDeletingLOs } = useMutation({
        mutationFn: () => deleteLearningObjectives(programmeId, [deleteLoId!]),
        onSuccess: () => {
            enqueueSnackbar('Successfully removed learning objective.', {
                key: 'remove-los-success',
                testId: 'remove-los-success',
                variant: 'curriculumSnackbar',
                severity: 'success',
            });

            setShowDeleteLO(false);
            setDeleteLOId(undefined);

            queryClient.invalidateQueries({
                queryKey: [`${CurriculumQueryKeys.PROGRAMME}-${programmeId}`],
            });
        },
        onError: (error: Error) => {
            enqueueSnackbar('Please refresh or try again.', {
                title: error.message,
                key: 'remove-los-error',
                testId: 'remove-los-error',
                variant: 'curriculumSnackbar',
                severity: 'error',
            });
        },
    });

    const onShowSlides = (learningObjectiveId: number) => {
        setShowSlidesPreview(true);
        setLoId(learningObjectiveId);
    };

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'programme_position',
            sort: 'asc',
        },
    ]);

    const columns: GridColDef<LOsType>[] = [
        {
            field: 'programme_position',
            type: 'string',
            headerName: 'Order',
            headerAlign: 'left',
            align: 'left',
            sortingOrder: ['asc', 'desc'],
            sortable: true,
            minWidth: 70,
            maxWidth: 70,
        },
        {
            field: 'title',
            type: 'string',
            headerName: 'Learning objective title',
            renderCell: ({ row }) => (
                <Tooltip
                    title={row.title}
                    enterDelay={500}
                    disableInteractive
                    arrow
                >
                    <Box
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        <TSLLink
                            to={`/learning-content/${row.id}`}
                            variant="body2"
                            data-testid="programme-link"
                        >
                            {row.title}
                        </TSLLink>
                    </Box>
                </Tooltip>
            ),
            sortable: true,
            minWidth: 250,
            flex: 1.5,
        },
        {
            field: 'id',
            type: 'string',
            headerName: 'ID',
            sortable: true,
            minWidth: 100,
            flex: 0.5,
        },
        {
            field: 'active',
            type: 'string',
            headerName: 'Status',
            renderCell: ({ row }) =>
                row.active ? (
                    <Typography color="success.main" variant="body2">
                        <Icons.Circle color="success" sx={sx.icon} />
                        Active
                    </Typography>
                ) : (
                    <Typography color="warning.main" variant="body2">
                        <Icons.Circle color="warning" sx={sx.icon} />
                        Inactive
                    </Typography>
                ),
            sortable: true,
            minWidth: 110,
            flex: 0.5,
        },
        {
            field: 'yearGroup',
            type: 'string',
            headerName: 'Year group',
            sortingOrder: ['asc', 'desc'],
            sortComparator: yearGroupSortComparator,
            renderCell: ({ row }) =>
                row.year_id ? (
                    <Chip
                        label={`Year ${row.year_id}`}
                        variant="outlined"
                        size="small"
                    />
                ) : (
                    '-'
                ),
            sortable: true,
            minWidth: 120,
            flex: 0.5,
        },
        {
            field: 'slides_counter',
            type: 'string',
            headerName: 'Slide count',
            valueGetter: ({ row }) => `${row.slides_counter} slides`,
            sortable: true,
            minWidth: 120,
            flex: 0.5,
        },
        {
            field: 'preview',
            headerName: '',
            renderCell: ({ row }) => (
                <LoadingButton
                    variant="outlined"
                    color="primary"
                    onClick={() => onShowSlides(row.id)}
                    loading={row.id === loId && isFetching}
                >
                    Preview
                </LoadingButton>
            ),
            sortable: false,
            minWidth: 100,
            maxWidth: 100,
        },
        {
            field: 'remove',
            headerName: '',
            renderCell: ({ row }) => (
                <Tooltip
                    title={
                        programmeStatus === ProgrammeStatusType.LIVE
                            ? "You can't remove an LO from a live programme"
                            : 'Remove LO'
                    }
                    disableInteractive
                    arrow
                >
                    <Box>
                        <IconButton
                            color="error"
                            onClick={() => {
                                setShowDeleteLO(true);
                                setDeleteLOId(row.id);
                            }}
                            data-testid="remove-lo-button"
                            disabled={
                                programmeStatus === ProgrammeStatusType.LIVE
                            }
                        >
                            <Icons.Close />
                        </IconButton>
                    </Box>
                </Tooltip>
            ),
            sortable: false,
            minWidth: 60,
            maxWidth: 60,
        },
    ];

    return (
        <>
            <DataGrid
                columns={columns}
                rows={los}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                disableColumnMenu
                disableColumnSelector
                disableColumnFilter
                disableDensitySelector
                disableRowSelectionOnClick
                hideFooter
                autoHeight
            />

            {showSlidesPreview && learningObjective && (
                <SlidesPreviewDialog
                    open={showSlidesPreview}
                    onClose={() => {
                        setShowSlidesPreview(false);
                        setLoId(undefined);
                    }}
                    startingSlide={0}
                    initialViewType={ViewType.VIEW}
                    learningObjective={learningObjective}
                />
            )}

            {showDeleteLO && (
                <ConfirmationDialog
                    open={showDeleteLO}
                    title={'Remove objective from programme?'}
                    confirmLabel="Yes"
                    loadingActionButton={isDeletingLOs}
                    message={
                        <SectionMessage
                            type="info"
                            title="This will not delete the learning objective from the Curriculum CMS"
                        />
                    }
                    onConfirm={() => deleteLOs()}
                    onClose={() => {
                        setShowDeleteLO(false);
                        setDeleteLOId(undefined);
                    }}
                    testId="delete-lo-dialog"
                />
            )}
        </>
    );
};

export default ProgrammeTable;
