import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import {
    Button,
    Dialog,
    Grid,
    Paper,
    RadioField,
    Typography,
} from '@thirdspacelearning/library';

import { ContentMap, LearningObjective } from '../../../types';
import { includesLO } from '../../../utils';
import { LearningObjectivesContent } from '../../learning-objectives';

const sx = {
    dialog: {
        minHeight: '720px',
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    toggle: {
        width: '100%',
    },
    paper: {
        padding: 2,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    titleWrapper: {
        justifyContent: 'space-between',
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
        '> *': {
            marginLeft: 1,
        },
    },
};

interface Props {
    onClose: () => void;
    open: boolean;
    linkLearningObjective: (values: any) => void;
    initialValues: LearningObjective | Record<string, never>;
    removeNode?: () => void;
    contentMap?: ContentMap;
}

const LinkObjectiveFormDialog: FC<Props> = ({
    onClose,
    open,
    linkLearningObjective,
    initialValues,
    removeNode,
    contentMap,
}) => {
    const navigate = useNavigate();

    const onEditObjective = () => {
        navigate('/learning-objectives');
    };

    const onSubmit = (values: any) => {
        linkLearningObjective(values);
    };

    const mustNotIncludeLO = (value: number) => {
        return contentMap && includesLO(contentMap, value)
            ? 'This Learning Objective is already in use for this assessment map. Please use another.'
            : undefined;
    };

    const buttons = [
        <Button
            onClick={onClose}
            color="primary"
            key="cancelLink"
            variant="outlined"
        >
            Cancel
        </Button>,
    ];

    if ('id' in initialValues) {
        buttons.unshift(
            <Button
                variant="contained"
                onClick={removeNode}
                color="secondary"
                key="removeNode"
            >
                Remove node
            </Button>
        );
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={sx.dialog}
            aria-labelledby="addEditTitle"
            maxWidth="lg"
            fullWidth
        >
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={(values) => {
                    if (!values.id) {
                        return {
                            [FORM_ERROR]: 'Please select a Learning Objective',
                        };
                    }

                    return { [FORM_ERROR]: mustNotIncludeLO(values.id) };
                }}
                render={({ handleSubmit, error, pristine, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <Paper elevation={0} sx={sx.paper}>
                            <Grid container sx={sx.titleWrapper}>
                                <Grid item>
                                    <Typography variant="h4">
                                        TSL Learning objectives
                                    </Typography>
                                </Grid>
                                <Grid item sx={sx.buttonWrapper}>
                                    {[
                                        ...buttons,
                                        <Button
                                            onClick={handleSubmit}
                                            color="primary"
                                            key="addLink"
                                            disabled={pristine || !valid}
                                        >
                                            Link learning objective
                                        </Button>,
                                    ]}
                                </Grid>
                            </Grid>
                            {error && (
                                <Typography
                                    variant="subtitle1"
                                    color="error"
                                    align="right"
                                    component="span"
                                >
                                    {error}
                                </Typography>
                            )}
                            {/* FIX THIS URGENTLY */}
                            <LearningObjectivesContent
                                onEditObjective={onEditObjective}
                                toggleField={(lo: LearningObjective) => (
                                    <Field
                                        name="id"
                                        component={RadioAdapter}
                                        type="radio"
                                        value={lo.id}
                                        parse={(value) =>
                                            value && Number(value)
                                        }
                                        size="small"
                                        sx={sx.toggle}
                                        color="primary"
                                    />
                                )}
                            />
                        </Paper>
                    </form>
                )}
            />
        </Dialog>
    );
};

/**
 * For some reason the compiler hates receiving this exact component
 * by way of @thirdspacelearning/library, despite the typing being practically the same
 * Leaving this here as a temporary solution to unblock upgrade work
 */
const RadioAdapter = ({
    input: { checked, value, name, onChange, ...otherInput },
    meta,
    ...rest
}: FieldRenderProps<string | number>) => (
    <RadioField
        {...rest}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        inputProps={otherInput}
    />
);

export default LinkObjectiveFormDialog;
