import { ElementType, FC } from 'react';

import {
    Box,
    Button,
    ButtonGroup,
    Icons,
    Typography,
} from '@thirdspacelearning/library';

const sx = {
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 1,

        '& .MuiTypography-root': {
            marginRight: 1,
        },
    },
};

interface Props {
    currentView: string;
    onChange: (name: 'complex' | 'simple') => void;
}

const ViewSwitcher: FC<Props> = ({ currentView, onChange }) => {
    const switcherConfig: {
        name: 'complex' | 'simple';
        Icon: ElementType;
    }[] = [
        { name: 'complex', Icon: Icons.Apps },
        { name: 'simple', Icon: Icons.ViewComfy },
    ];

    return (
        <Box sx={sx.wrapper}>
            <Typography>View:</Typography>

            <ButtonGroup>
                {switcherConfig.map((option) => {
                    const handleClick = () => {
                        onChange(option.name);
                    };

                    return (
                        <Button
                            color="primary"
                            variant={
                                currentView === option.name
                                    ? 'contained'
                                    : 'outlined'
                            }
                            onClick={handleClick}
                            key={option.name}
                        >
                            <option.Icon />
                        </Button>
                    );
                })}
            </ButtonGroup>
        </Box>
    );
};

export default ViewSwitcher;
