import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Grid,
    Icons,
    Loading,
    Theme,
    Typography,
} from '@thirdspacelearning/library';

import { useAppDispatch, useAppSelector } from '../../../state/store';
import {
    getRevisionLearningObjectives,
    selectRevisionLearningObjectives,
    selectRevisionLearningObjectivesError,
    updateLearningObjectiveRevisionOrder,
} from '../../../state/learning-objectives';
import { ChangePosition } from '../../shared';

import { LearningObjectiveType } from '../../../types';

import { ComplexLearningObjectiveCard } from './MapCards';

const sx = {
    gridContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
    },
    gridItem: (theme: Theme) => ({
        position: 'relative',
        padding: theme.spacing(0, 4, 4, 4),
    }),
    positionItem: {
        paddingLeft: 0,
    },
    downArrow: {
        position: 'absolute',
        right: 'calc(50% - 17.5px)',
        bottom: -7,
    },
    py: {
        paddingBottom: 3,
    },
};

interface Props {
    learningObjectiveType: LearningObjectiveType;
}

const RevisionLearningMap: FC<Props> = ({ learningObjectiveType }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const learningObjectives = useAppSelector(selectRevisionLearningObjectives);
    const error = useAppSelector(selectRevisionLearningObjectivesError);

    useEffect(() => {
        dispatch(getRevisionLearningObjectives(learningObjectiveType));
    }, [dispatch, learningObjectiveType]);

    const onEdit = () => {
        navigate('/learning-objectives');
    };

    const onPositionChange = (
        learningObjectiveId: number,
        newPosition: number,
        oldPosition: number,
    ) => {
        return dispatch(
            updateLearningObjectiveRevisionOrder(
                learningObjectiveId,
                newPosition,
                oldPosition,
                learningObjectiveType,
            ),
        );
    };

    return (
        <>
            {error && (
                <Typography sx={sx.py} color="error">
                    {error}
                </Typography>
            )}
            {!error && !learningObjectives && <Loading />}
            {!error && learningObjectives && (
                <>
                    {learningObjectives.map((lo, index) => (
                        <Grid key={lo.id} container sx={sx.gridContainer}>
                            <Grid item xs={8} sx={sx.gridItem}>
                                <ComplexLearningObjectiveCard
                                    learningObjective={lo}
                                    onEdit={onEdit}
                                    type="node"
                                />
                                <Box sx={sx.downArrow}>
                                    <Icons.KeyboardArrowDown
                                        color="primary"
                                        fontSize="large"
                                    />
                                </Box>
                            </Grid>
                            {'revision_order' in lo && (
                                <ChangePosition
                                    currentPosition={lo.revision_order}
                                    onChangePosition={(newPosition) =>
                                        onPositionChange(
                                            lo.id,
                                            newPosition,
                                            lo.revision_order,
                                        )
                                    }
                                />
                            )}
                        </Grid>
                    ))}
                </>
            )}
        </>
    );
};

export default RevisionLearningMap;
