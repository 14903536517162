import { FC } from 'react';

import {
    Box,
    Button,
    Card,
    CardContent,
    Icons,
    Typography,
} from '@thirdspacelearning/library';

import { environment } from '../../../../environments/environment';

import { ContentDomainInfo } from '../../../shared';

import {
    ActiveTag,
    ContentDomainTag,
    ImpactTag,
    LearningContentTag,
    LOIDTag,
    YearGroupTag,
} from '../Tags';

import { LearningObjectiveCardProps } from './LearningObjectiveCardProps';

const sx = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 2,
        minHeight: '230px',
        justifyContent: 'space-between',
        '&:last-child': {
            paddingBottom: 2, // overwrite a global style
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    tags: {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-start',
        flexWrap: 'wrap',
        '> p': {
            marginRight: 1,
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
};

const ComplexLearningObjectiveCard: FC<LearningObjectiveCardProps> = ({
    learningObjective,
    onEdit,
    type,
    isInDifferentYearGroup = false,
    isInDfferentStrand = false,
}) => (
    <Card data-testid={`loCard-${type}`}>
        <CardContent sx={sx.content}>
            <Box sx={sx.header}>
                <Box sx={sx.tags}>
                    {'impact' in learningObjective && (
                        <ImpactTag impact={learningObjective.impact} />
                    )}
                    <ActiveTag
                        active={learningObjective.active}
                        deleted={learningObjective.deleted}
                    />
                    <ContentDomainTag learningObjective={learningObjective} />
                    <LOIDTag
                        id={learningObjective.id}
                        isInDifferentStrand={isInDfferentStrand}
                    />
                    <YearGroupTag
                        year={learningObjective.year_id}
                        isInDifferentYearGroup={isInDifferentYearGroup}
                    />
                </Box>
                {onEdit && (
                    <Button
                        variant="text"
                        startIcon={<Icons.Edit />}
                        color="primary"
                        onClick={onEdit}
                    >
                        Edit
                    </Button>
                )}
            </Box>
            <Typography variant="h6">{learningObjective.title}</Typography>
            <LearningContentTag
                slideCounter={learningObjective.slides_counter}
                learningObjective={learningObjective}
                deleted={learningObjective.deleted}
            />
            <Box sx={sx.footer}>
                <Button
                    color="primary"
                    variant="outlined"
                    href={`${environment.mainAppBaseUrl}/questions?lesson_id=${learningObjective.id}&from=cms`}
                    target="_blank"
                >
                    View questions
                </Button>
                <ContentDomainInfo learningObjective={learningObjective} />
            </Box>
        </CardContent>
    </Card>
);

export default ComplexLearningObjectiveCard;
