import { FC, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LoadingButton,
    SectionMessage,
} from '@thirdspacelearning/library';

import ProgrammeLOReorderTable from './ProgrammeLOReorderTable';

import { LOsType, ProgrammeStatusType } from '../../../types';

import { CurriculumQueryKeys, reorderLearningObjectives } from '@api';

const sx = {
    dialog: {
        '& .MuiDialog-paper': {
            p: 3,
            gap: 2,
        },
    },
};

type Props = {
    open: boolean;
    onClose: (show: boolean) => void;
    programmeId: number;
    programmeLOs: LOsType[];
    programmeStatus: ProgrammeStatusType | null;
};

const ProgrammeLOReorder: FC<Props> = ({
    open,
    onClose,
    programmeId,
    programmeLOs,
    programmeStatus,
}) => {
    const queryClient = useQueryClient();

    const [sortedLOs, setSortedLOs] = useState(
        [...programmeLOs].sort(
            (a, b) =>
                Number(a.programme_position) - Number(b.programme_position),
        ),
    );

    const { mutate: reorderLOs, isPending } = useMutation({
        mutationFn: (losList: any[]) =>
            reorderLearningObjectives(programmeId, losList),
        onSuccess: () => {
            enqueueSnackbar('Successfully reordered learning objectives.', {
                key: 'reorder-los-success',
                testId: 'reorder-los-success',
                variant: 'curriculumSnackbar',
                severity: 'success',
            });

            onClose(false);

            queryClient.invalidateQueries({
                queryKey: [`${CurriculumQueryKeys.PROGRAMME}-${programmeId}`],
            });
        },
        onError: (error: Error) => {
            enqueueSnackbar('Please refresh or try again.', {
                title: error.message,
                key: 'reorder-los-error',
                testId: 'reorder-los-error',
                variant: 'curriculumSnackbar',
                severity: 'error',
            });
        },
    });

    const handleClose = (
        event?: unknown,
        reason?: 'backdropClick' | 'escapeKeyDown',
    ) => {
        if (reason && reason === 'backdropClick') {
            return;
        }
        onClose(false);
    };

    const onSubmit = () => {
        const reorderLOsList = sortedLOs.map((lo, index) => ({
            id: lo.id,
            position: index + 1,
        }));

        reorderLOs(reorderLOsList);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={sx.dialog}
            maxWidth={'md'}
            data-testid="lo-reorder"
        >
            <DialogTitle
                align="center"
                fontWeight="bold"
                sx={{ p: 0 }}
                data-testid="lo-reorder-title"
            >
                Reorder objectives
            </DialogTitle>

            {programmeStatus === ProgrammeStatusType.LIVE && (
                <SectionMessage
                    type="warning"
                    title="This programme is live"
                    body="Any changes made to this programme will affect pupils who are currently on it."
                />
            )}

            <DialogContent sx={{ p: 0 }}>
                <ProgrammeLOReorderTable
                    sortedLOs={sortedLOs}
                    setSortedLOs={setSortedLOs}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => onClose(false)}
                    disabled={isPending}
                    fullWidth
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    loading={isPending}
                    disabled={isPending}
                    fullWidth
                    data-testid="lo-reorder-save"
                >
                    Save order
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ProgrammeLOReorder;
