import { FC } from 'react';

import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Icons,
    Theme,
    Typography,
} from '@thirdspacelearning/library';

import { stepsToSuccess } from '../../../utils';

import { LearningObjective, Slide } from '../../../types';

const sx = {
    slideCard: (theme: Theme) => ({
        display: 'flex',
        minHeight: '300px',
        margin: theme.spacing(2, 0),
    }),
    slideMedia: {
        width: '100%',
        height: '300px',
    },
    tutorNotes: {
        overflow: 'auto',
        flexGrow: 1,
    },
    slideActions: {
        alignItems: 'flex-start',
        flex: '0 0 20%',
        flexDirection: 'column',
    },
    cardMedia: {
        flex: '0 0 450px',
        padding: 2,
    },
    marginTop: {
        marginTop: 2,
    },
};

interface Props {
    slide: Slide;
    onChangeActive: () => void;
    onEdit: () => void;
    onPreview: () => void;
    learningObjective: LearningObjective;
}

const SlideCard: FC<Props> = ({
    slide,
    onChangeActive,
    onEdit,
    onPreview,
    learningObjective,
}) => (
    <Card key={slide.id} sx={sx.slideCard} raised data-testid="slideCard">
        <Box sx={sx.cardMedia}>
            <CardMedia
                image={slide.image_path}
                title="Slide image"
                sx={sx.slideMedia}
            />
        </Box>
        <CardContent sx={sx.tutorNotes}>
            <Typography variant="caption" color="textSecondary">
                Tutor Notes
            </Typography>
            <Typography
                component="div"
                dangerouslySetInnerHTML={{ __html: slide.tutor_notes }}
                variant="body2"
            />
        </CardContent>
        <CardActions sx={sx.slideActions}>
            <Box>
                <Button
                    variant="text"
                    color="primary"
                    startIcon={<Icons.Visibility />}
                    onClick={onPreview}
                >
                    Preview
                </Button>
                <Button
                    variant="text"
                    color="primary"
                    startIcon={<Icons.Edit />}
                    onClick={onEdit}
                >
                    Edit
                </Button>
                <Button variant="text" color="primary" onClick={onChangeActive}>
                    {slide.active ? 'Deactivate' : 'Activate'}
                </Button>
            </Box>
            {slide.slide_type && (
                <Box sx={sx.marginTop}>
                    <Typography variant="caption" color="textSecondary">
                        Slide type
                    </Typography>
                    <Typography>
                        {stepsToSuccess(slide.slide_type, learningObjective)}
                    </Typography>
                </Box>
            )}
            {slide.learning_content && (
                <Box sx={sx.marginTop}>
                    <Typography variant="caption" color="textSecondary">
                        Step
                    </Typography>
                    <Typography>{slide.learning_content.title}</Typography>
                </Box>
            )}
        </CardActions>
    </Card>
);

export default SlideCard;
