import { ErrorInfo } from 'react';
import { createAction } from '@reduxjs/toolkit';

export const addGlobalContext = createAction<{
    key: string;
    value: any;
}>('eventTracking/addGlobalContext');

export const customUserAction = createAction<{
    name: string;
    extraInfo: any;
}>('eventTracking/customUserAction');

export const customError = createAction<{
    error: Error;
    info?: ErrorInfo;
}>('eventTracking/customError');

export const pageChange = createAction<{
    path: string;
}>('eventTracking/pageChange');

export const groupTracking = createAction<{ schoolId?: string }>(
    'eventTracking/groupTracking'
);
