import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
    ActionPanel,
    Box,
    Button,
    Chip,
    Icons,
    Loading,
    Typography,
} from '@thirdspacelearning/library';

import ProgrammeTable from './ProgrammeTable';
import {
    ProgrammeDeletion,
    ProgrammeForm,
    ProgrammeLOReorder,
    ProgrammeStatus,
} from '../programme-details';
import { ProgrammeLOSelection } from '../programme-los';

import { ChipMenu } from '../../shared';
import { ProgrammeStatusType, ProgrammeType } from '../../types';
import { statusMap } from '../../utils';

import {
    ErrorActionPanel,
    PageLayout,
    PlaceholderActionPanel,
} from '../../../shared';

import { CurriculumQueryKeys, getProgramme, QUERY_STATUS } from '@api';
import { capitalise } from '@utils';

import infoSvg from '@assets/info.svg';

const sx = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    backButton: { mx: 4, alignSelf: 'flex-start' },
    actionPanel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: 3,
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: 2,
    },
};

const ProgrammePage = () => {
    const { programmeId } = useParams();

    const { showProgrammesOnCurriculumCms } = useFlags();

    // Programme Details
    const [showEditProgramme, setShowEditProgramme] = useState(false);

    // Programme Deletion
    const [showDeleteProgramme, setShowDeleteProgramme] = useState(false);

    // Show LO modal
    const [showLOSelection, setShowLOSelection] = useState(false);

    // Show LO modal
    const [showLOReorder, setShowLOReorder] = useState(false);

    // Programme Status
    const [selectedStatus, setSelectedStatus] =
        useState<ProgrammeStatusType | null>(null);

    // Query
    const { status, data: programme } = useQuery<ProgrammeType>({
        queryKey: [`${CurriculumQueryKeys.PROGRAMME}-${programmeId}`],
        queryFn: () => getProgramme(programmeId),
        enabled: !!programmeId,
        retry: 1,
        refetchOnWindowFocus: false,
    });

    const onStatusChange = (status: ProgrammeStatusType) => {
        if (status === programme?.status) {
            return;
        }

        setSelectedStatus(status);
    };

    if (showProgrammesOnCurriculumCms === false) {
        return <PlaceholderActionPanel />;
    }

    const isProgrammeLive = programme?.status === ProgrammeStatusType.LIVE;

    return (
        <>
            {status === QUERY_STATUS.pending && (
                <Loading loadingMessage="Loading programme..." />
            )}

            {status === QUERY_STATUS.error && (
                <ErrorActionPanel
                    error="There was an error loading the programme"
                    message="Please refresh the page and try again. If the problem persists, please let us know."
                />
            )}

            {status === QUERY_STATUS.success && programme && (
                <Box sx={sx.root}>
                    <Box display="flex" mb={2} justifyContent="space-between">
                        <Button
                            component={Link}
                            to="/programmes"
                            variant="text"
                            color="primary"
                            startIcon={<Icons.ArrowBackRounded />}
                            sx={sx.backButton}
                        >
                            Return to all programmes
                        </Button>
                        <Box mr={4} display="flex" gap={1}>
                            <Button
                                key="edit-programme"
                                variant="outlined"
                                color="primary"
                                onClick={() => setShowEditProgramme(true)}
                                data-testid="edit-programme-button"
                            >
                                Edit Details
                            </Button>
                            {isProgrammeLive && (
                                <Button
                                    key="delete-programme"
                                    variant="outlined"
                                    color="error"
                                    onClick={() => setShowDeleteProgramme(true)}
                                    data-testid="delete-programme-button"
                                >
                                    Delete Programme
                                </Button>
                            )}
                        </Box>
                    </Box>

                    <PageLayout
                        title={programme.title}
                        buttons={[
                            <ChipMenu
                                items={Object.values(ProgrammeStatusType)}
                                onChange={onStatusChange}
                                label={
                                    capitalise(programme.status) || 'No status'
                                }
                                color={
                                    programme.status
                                        ? statusMap[programme.status]
                                        : 'default'
                                }
                                selected={programme.status}
                            />,
                            <Chip
                                label={`Key stage ${
                                    programme.key_stage || '-'
                                }`}
                                variant="outlined"
                            />,
                            <Chip
                                label={`Year ${programme.year_id || '-'}`}
                                variant="outlined"
                            />,
                        ]}
                    >
                        <Box sx={sx.content}>
                            <Typography
                                variant="body1"
                                maxWidth="900px"
                                color="text.secondary"
                            >
                                {programme.description}
                            </Typography>

                            {programme.learning_objectives.length === 0 && (
                                <Box
                                    sx={sx.actionPanel}
                                    data-testid="no-los-panel"
                                >
                                    <ActionPanel
                                        buttonAction={() =>
                                            setShowLOSelection(true)
                                        }
                                        buttonLabel="Add learning objective"
                                        decoration={
                                            <img
                                                src={infoSvg}
                                                alt="An error occurred"
                                            />
                                        }
                                        title="Start adding learning objectives to the programme"
                                    />
                                </Box>
                            )}

                            {programme.learning_objectives.length > 0 && (
                                <>
                                    <Box sx={sx.actions}>
                                        <Typography
                                            fontWeight="bold"
                                            data-testid="los-count"
                                        >
                                            {`${
                                                programme.learning_objectives
                                                    .length
                                            } objective${
                                                programme.learning_objectives
                                                    .length > 1
                                                    ? 's'
                                                    : ''
                                            }`}
                                        </Typography>
                                        <Box display="flex" gap={2}>
                                            {!isProgrammeLive && (
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() =>
                                                        setShowLOSelection(true)
                                                    }
                                                    disabled={isProgrammeLive}
                                                    data-testid="add-los-button"
                                                >
                                                    Add learning objectives
                                                </Button>
                                            )}
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() =>
                                                    setShowLOReorder(true)
                                                }
                                                data-testid="reorder-los-button"
                                            >
                                                Reorder objectives
                                            </Button>
                                        </Box>
                                    </Box>

                                    <ProgrammeTable
                                        los={programme.learning_objectives}
                                        programmeId={programme.id}
                                        programmeStatus={programme.status}
                                    />
                                </>
                            )}
                        </Box>

                        {/* Edit programme details */}
                        {showEditProgramme && (
                            <ProgrammeForm
                                open={showEditProgramme}
                                onClose={setShowEditProgramme}
                                programme={programme}
                            />
                        )}

                        {/* Edit Status */}
                        {selectedStatus && (
                            <ProgrammeStatus
                                programmeId={programme.id}
                                selectedStatus={selectedStatus}
                                setSelectedStatus={setSelectedStatus}
                                currentStatus={programme.status}
                            />
                        )}

                        {showDeleteProgramme && (
                            <ProgrammeDeletion
                                open={showDeleteProgramme}
                                programmeId={programme.id}
                                programmeTitle={programme.title}
                                setShowDeleteProgramme={setShowDeleteProgramme}
                            />
                        )}

                        {showLOSelection && !isProgrammeLive && (
                            <ProgrammeLOSelection
                                open={showLOSelection}
                                onClose={setShowLOSelection}
                                programmeId={programme.id}
                                programmeLOs={programme.learning_objectives}
                            />
                        )}

                        {showLOReorder && (
                            <ProgrammeLOReorder
                                open={true}
                                onClose={setShowLOReorder}
                                programmeId={programme.id}
                                programmeLOs={programme.learning_objectives}
                                programmeStatus={programme.status}
                            />
                        )}
                    </PageLayout>
                </Box>
            )}
        </>
    );
};

export default ProgrammePage;
