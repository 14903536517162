import { ErrorInfo, useCallback } from 'react';
import {
    customError,
    customUserAction,
    groupTracking,
    pageChange,
} from '../actions';

export const useEventTracking = (dispatch: any) => {
    const sendUserTracking = useCallback(
        (name: any, extraInfo: any) => {
            dispatch(
                customUserAction({
                    name,
                    extraInfo,
                })
            );
        },
        [dispatch]
    );

    const sendErrorTracking = useCallback(
        (error: Error, info?: ErrorInfo) => {
            dispatch(
                customError({
                    error,
                    info,
                })
            );
        },
        [dispatch]
    );

    const sendPageTracking = useCallback(
        (path: string) => {
            dispatch(
                pageChange({
                    path,
                })
            );
        },
        [dispatch]
    );

    const groupEventTracking = useCallback(
        (schoolId: string) => {
            dispatch(
                groupTracking({
                    schoolId,
                })
            );
        },
        [dispatch]
    );

    return {
        sendUserTracking,
        sendErrorTracking,
        sendPageTracking,
        groupEventTracking,
    };
};
