import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Grid, Icons, Loading } from '@thirdspacelearning/library';

import { useAppDispatch, useAppSelector } from '../../state/store';
import { getSingleLearningObjective } from '../../state/learning-objectives';
import {
    editLearningObjectiveContent,
    editSlide,
    getSlides,
    selectLearningObjectiveById,
    selectSlidesBySetLoId,
} from '../../state/learning-objectives/learning-content';

import { ChangePosition, PageLayout } from '../shared';
import { Slide } from '../../types';

import { SlideCard } from './components';
import SlidesPreviewDialog, { ViewType } from './SlidesPreviewDialog';

const sx = {
    gridContainer: {
        alignItems: 'center',
    },
};

interface Props {}

const LearningContentPage: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { learningObjectiveId } = useParams<'learningObjectiveId'>();
    const learningObjective = useAppSelector(selectLearningObjectiveById);
    const slides = useAppSelector(selectSlidesBySetLoId);

    const [slidePreviewIndex, setSlidePreviewIndex] = useState<number>(-1);
    const [slidePreviewView, setSlidePreviewView] = useState<ViewType | null>(
        null,
    );

    const showSlidesPreview = (startingIndex = 0) => {
        setSlidePreviewView(ViewType.VIEW);
        setSlidePreviewIndex(startingIndex);
    };

    const editSlidePreview = (startingIndex = 0) => {
        setSlidePreviewView(ViewType.EDIT);
        setSlidePreviewIndex(startingIndex);
    };

    const addSlidePreview = useCallback(() => {
        if (slides) {
            setSlidePreviewView(ViewType.ADD);
            setSlidePreviewIndex(slides.length);
        }
    }, [slides]);

    useEffect(() => {
        dispatch(editLearningObjectiveContent(Number(learningObjectiveId)));

        return () => {
            dispatch(editLearningObjectiveContent(null));
        };
    }, [learningObjectiveId, dispatch]);

    useEffect(() => {
        if (!learningObjective) {
            dispatch(getSingleLearningObjective(Number(learningObjectiveId)));
        } else {
            dispatch(
                getSlides(Number(learningObjectiveId), { sort: 'position' }),
            );
        }
    }, [learningObjective, dispatch, learningObjectiveId]);

    useEffect(() => {
        if (slides && !slides.length) {
            addSlidePreview();
        }
    }, [slides, addSlidePreview]);

    const changeActive = (slideId: number, active: boolean) => {
        dispatch(editSlide(slideId, Number(learningObjectiveId), { active }));
    };

    const changeSlidePosition = (slide: Slide, position: number) => {
        dispatch(
            editSlide(slide.id, Number(learningObjectiveId), { position }),
        );
    };

    const onCloseDialog = () => {
        setSlidePreviewView(null);
    };

    const onClickAdd = () => {
        addSlidePreview();
    };

    const onClickPreview = () => {
        showSlidesPreview();
    };

    const getPageTitle = () => {
        if (learningObjective) {
            return learningObjective.title;
        } else {
            return 'View/Edit Learning Content';
        }
    };

    const pageTitle = getPageTitle();

    if (!slides || !learningObjective) {
        return <Loading />;
    }

    return (
        <PageLayout
            title={pageTitle}
            buttons={[
                <Button
                    variant="contained"
                    color="secondary"
                    key="addSlide"
                    onClick={onClickAdd}
                >
                    Add a slide
                </Button>,
                <Button
                    color="primary"
                    key="preview"
                    startIcon={<Icons.Visibility />}
                    onClick={onClickPreview}
                >
                    Preview slides
                </Button>,
            ]}
        >
            {slides.map((slide, index) => (
                <Grid container key={slide.id} sx={sx.gridContainer}>
                    <Grid xs={11} item>
                        <SlideCard
                            slide={slide}
                            onChangeActive={() =>
                                changeActive(slide.id, !slide.active)
                            }
                            onEdit={() => editSlidePreview(index)}
                            onPreview={() => showSlidesPreview(index)}
                            learningObjective={learningObjective}
                        />
                    </Grid>
                    <ChangePosition
                        currentPosition={slide.position}
                        onChangePosition={(position) =>
                            changeSlidePosition(slide, position)
                        }
                    />
                </Grid>
            ))}
            {slidePreviewView && (
                <SlidesPreviewDialog
                    open={!!slidePreviewView}
                    onClose={onCloseDialog}
                    startingSlide={slidePreviewIndex}
                    initialViewType={slidePreviewView}
                    learningObjective={learningObjective}
                    editable
                />
            )}
        </PageLayout>
    );
};

export default LearningContentPage;
