import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { authReducer } from '@tsl-frontend/tsl-auth';

import contentMaps from './content-maps/reducer';
import feedback from './feedback/reducer';
import learningObjectives from './learning-objectives/reducer';
import learningContent from './learning-objectives/learning-content/reducer';
import strands from './strands/reducer';
import years from './years/reducer';

import { environment } from '../environments/environment';

const rootReducers = combineReducers({
    contentMaps,
    feedback,
    learningContent,
    learningObjectives,
    strands,
    auth: authReducer,
    years,
});

export const store = configureStore({
    reducer: rootReducers,
    devTools: !environment.production,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

type AppDispatch = typeof store.dispatch;
type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
