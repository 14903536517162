import { FC, MouseEvent, useState } from 'react';

import {
    Button,
    Definition,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Icons,
    Typography,
} from '@thirdspacelearning/library';

import { LearningObjective } from '../../types';

const sx = {
    close: {
        position: 'absolute',
        right: 8,
        top: 8,
    },
};

interface Props {
    learningObjective: LearningObjective;
}

const StrandInfo: FC<Props> = ({ learningObjective }) => {
    const [showInfo, setShowInfo] = useState<boolean>(false);

    const onInfoClick = (_e: MouseEvent<HTMLElement>) => {
        setShowInfo(true);
    };

    const closeDialog = () => {
        setShowInfo(false);
    };

    return (
        <>
            <IconButton color="primary" onClick={onInfoClick} size="small">
                <Icons.Info />
            </IconButton>
            {showInfo && (
                <Dialog
                    open={showInfo}
                    onClose={closeDialog}
                    aria-labelledby="siTitle"
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle id="siTitle">
                        {learningObjective.title}

                        <Button
                            variant="text"
                            endIcon={<Icons.Close />}
                            onClick={closeDialog}
                            color="primary"
                            sx={sx.close}
                        >
                            Close
                        </Button>
                    </DialogTitle>
                    <DialogContent>
                        {learningObjective.strand ? (
                            <Definition
                                label="NC Strand"
                                value={learningObjective.strand.title}
                            />
                        ) : (
                            <Typography gutterBottom>
                                There are no strands associated with this
                                learning objective
                            </Typography>
                        )}
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default StrandInfo;
