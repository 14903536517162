import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Button,
    ButtonGroup,
    Icons,
    Theme,
    Typography,
} from '@thirdspacelearning/library';

import { LearningObjective } from '../../../../types';

import { SlidesPreviewDialog, ViewType } from '../../../learning-content';

const sx = {
    wrapper: (hasContent: boolean) => (theme: Theme) => ({
        display: 'flex',
        border: 1,
        borderColor: hasContent ? 'secondary.dark' : 'grey.300',
        margin: theme.spacing(1, 0),
    }),
    tag: (hasContent: boolean) => ({
        display: 'inline-flex',
        alignItems: 'center',
        paddingRight: 1,
        paddingLeft: 0.5,
        backgroundColor: hasContent ? 'secondary.dark' : 'grey.300',
        color: hasContent ? 'text.primary' : 'text.secondary',
        '& .MuiSvgIcon': {
            marginRight: 1,
        },
    }),
};

interface Props {
    slideCounter: number;
    learningObjective: LearningObjective;
    deleted: boolean;
}

const LearningContentTag: FC<Props> = ({
    slideCounter,
    learningObjective,
    deleted,
}) => {
    const [showSlidePreview, setShowSlidePreview] = useState<boolean>(false);
    const navigate = useNavigate();

    const onClickPreview = () => {
        setShowSlidePreview(true);
    };

    const onCloseDialog = () => {
        setShowSlidePreview(false);
    };

    const onAddEditObjective = () => {
        navigate(`/learning-content/${learningObjective.id}`);
    };

    const content = !!slideCounter;
    return (
        <Box sx={sx.wrapper(content)}>
            <Typography sx={sx.tag(content)}>
                {content ? <Icons.CheckBox /> : <Icons.CheckBoxOutlineBlank />}{' '}
                Learning content
            </Typography>

            {content ? (
                <ButtonGroup variant="text" color="secondary">
                    <Button
                        // @ts-ignore
                        size="small"
                        color="primary"
                        variant="text"
                        onClick={onClickPreview}
                    >
                        Preview
                    </Button>
                    <Button
                        // @ts-ignore
                        size="small"
                        color="primary"
                        variant="text"
                        onClick={onAddEditObjective}
                        disabled={deleted}
                    >
                        Edit
                    </Button>
                </ButtonGroup>
            ) : (
                <Button
                    // @ts-ignore
                    target="_blank"
                    variant="text"
                    size="small"
                    color="primary"
                    onClick={onAddEditObjective}
                    disabled={deleted}
                >
                    Add
                </Button>
            )}
            {showSlidePreview && (
                <SlidesPreviewDialog
                    open={showSlidePreview}
                    onClose={onCloseDialog}
                    startingSlide={0}
                    initialViewType={ViewType.VIEW}
                    learningObjective={learningObjective}
                />
            )}
        </Box>
    );
};

export default LearningContentTag;
