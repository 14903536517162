import { FC } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { v4 as uuidv4 } from 'uuid';

import {
    Box,
    Button,
    Icons,
    TextFinalField,
} from '@thirdspacelearning/library';

import { ChangePosition } from '../../../../shared';

const sx = {
    wrapper: {
        marginTop: 2,
        paddingTop: 1,
        paddingBottom: 1,
    },
    arrayWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTextField-root': {
            margin: 0,
        },
    },
    buttonMargin: {
        marginLeft: 2,
    },
};

interface Props {
    push: (name: string, value: any) => void;
}

const LearningContentsAttributes: FC<Props> = ({ push }) => {
    const onClickAdd = () => {
        push('learning_contents', { key: uuidv4(), title: '' });
    };

    return (
        <Box sx={sx.wrapper}>
            <FieldArray name="learning_contents">
                {({ fields }) => {
                    return fields.map((name, index) => {
                        const key =
                            fields.value[index].id || fields.value[index].key;

                        const onClickClear = () => {
                            fields.remove(index);
                        };

                        const onChangePosition = (newPosition: any) => {
                            fields.move(index, newPosition - 1);
                        };

                        return (
                            <Box key={key} sx={sx.arrayWrapper}>
                                <Field
                                    name={`${name}.title`}
                                    label={`Step ${index + 1}`}
                                    variant="outlined"
                                    color="primary"
                                    component={TextFinalField}
                                    fullWidth
                                    multiline
                                    className="noMargin"
                                    autoFocus={index + 1 === fields.length}
                                />
                                <Box sx={sx.buttonMargin}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={onClickClear}
                                    >
                                        Clear
                                    </Button>
                                </Box>
                                <ChangePosition
                                    currentPosition={index + 1}
                                    hideCurrentPosition
                                    onChangePosition={onChangePosition}
                                />
                            </Box>
                        );
                    });
                }}
            </FieldArray>
            <Button variant="contained" color="primary" onClick={onClickAdd}>
                <Icons.Add />
            </Button>
        </Box>
    );
};

export default LearningContentsAttributes;
