import { FC } from 'react';

import {
    Chip,
    DataGrid,
    GridColDef,
    GridPaginationModel,
    GridRowParams,
    GridRowSelectionModel,
    GridSortModel,
    Icons,
    LinearProgress,
    Typography,
} from '@thirdspacelearning/library';

import { yearGroupSortComparator } from '../../utils';

import { LearningObjective } from '../../../../types';

const sx = {
    icon: { width: '10px', height: '10px', mr: 0.5 },
};

type Props = {
    learningObjectives: LearningObjective[];
    total: number;
    paginationModel: GridPaginationModel;
    onPaginationModelChange: (paginationModel: GridPaginationModel) => void;
    onSortModelChange: (sortModel: GridSortModel) => void;
    rowSelectionModel: GridRowSelectionModel;
    setRowSelectionModel: (rowSelectionModel: GridRowSelectionModel) => void;
    loading: boolean;
    programmeLoIds: number[];
};

const ProgrammeLOTable: FC<Props> = ({
    learningObjectives,
    total,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    loading,
    rowSelectionModel,
    setRowSelectionModel,
    programmeLoIds,
}) => {
    const columns: GridColDef<LearningObjective>[] = [
        {
            field: 'title',
            type: 'string',
            headerName: 'Learning objective title',
            headerAlign: 'left',
            align: 'left',
            sortable: true,
            flex: 1,
        },
        {
            field: 'id',
            type: 'string',
            headerName: 'ID',
            headerAlign: 'left',
            align: 'left',
            sortable: true,
            minWidth: 80,
            flex: 0.25,
        },
        {
            field: 'active',
            type: 'string',
            headerName: 'Status',
            renderCell: ({ row }) =>
                row.active ? (
                    <Typography color="success.main" variant="body2">
                        <Icons.Circle color="success" sx={sx.icon} />
                        Active
                    </Typography>
                ) : (
                    <Typography color="warning.main" variant="body2">
                        <Icons.Circle color="warning" sx={sx.icon} />
                        Inactive
                    </Typography>
                ),
            sortable: true,
            minWidth: 110,
            flex: 0.25,
        },
        {
            field: 'yearGroup',
            type: 'string',
            headerName: 'Year group',
            sortingOrder: ['asc', 'desc'],
            sortComparator: yearGroupSortComparator,
            renderCell: ({ row }) =>
                row.year_id ? (
                    <Chip
                        label={`Year ${row.year_id}`}
                        variant="outlined"
                        size="small"
                    />
                ) : (
                    '-'
                ),
            sortable: true,
            minWidth: 120,
            flex: 0.25,
        },
        {
            field: 'slides_counter',
            type: 'string',
            headerName: 'Slide count',
            valueGetter: ({ row }) => `${row.slides_counter} slides`,
            sortable: false,
            minWidth: 120,
            flex: 0.25,
        },
    ];

    return (
        <DataGrid
            columns={columns}
            rows={learningObjectives}
            rowCount={total}
            paginationMode="server"
            pageSizeOptions={[10, 25, 50]}
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            filterMode="server"
            sortingMode="server"
            onSortModelChange={onSortModelChange}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={setRowSelectionModel}
            loading={loading}
            slots={{
                loadingOverlay: LinearProgress,
            }}
            disableColumnMenu
            checkboxSelection
            keepNonExistentRowsSelected
            isRowSelectable={(params: GridRowParams) =>
                !programmeLoIds.includes(params.row.id) && !loading
            }
        />
    );
};

export default ProgrammeLOTable;
