import { FC, Fragment, useState } from 'react';

import {
    Box,
    Button,
    Definition,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Icons,
    Theme,
    Typography,
} from '@thirdspacelearning/library';

import { isSatsType, isStandardType, LearningObjective } from '../../types';

const sx = {
    divider: (theme: Theme) => ({
        margin: theme.spacing(3, 0),
    }),
};

interface Props {
    learningObjective: LearningObjective;
}

const ContentDomainInfo: FC<Props> = ({ learningObjective }) => {
    const [showInfo, setShowInfo] = useState<boolean>(false);

    const contentDomains = isStandardType(learningObjective)
        ? [learningObjective.content_domain]
        : isSatsType(learningObjective)
          ? learningObjective.content_domains
          : [];

    const onOpenDialog = () => {
        setShowInfo(true);
    };

    const onCloseDialog = () => {
        setShowInfo(false);
    };

    return (
        <>
            <IconButton color="primary" onClick={onOpenDialog} size="small">
                <Icons.Info />
            </IconButton>
            {showInfo && (
                <Dialog
                    open={showInfo}
                    onClose={onCloseDialog}
                    aria-labelledby="cdiTitle"
                    maxWidth="md"
                    fullWidth
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <DialogTitle id="cdiTitle">
                            {learningObjective.title}
                        </DialogTitle>
                        <Button
                            variant="text"
                            endIcon={<Icons.Close />}
                            onClick={onCloseDialog}
                            color="primary"
                        >
                            Close
                        </Button>
                    </Box>
                    <DialogContent>
                        {contentDomains.length ? (
                            contentDomains.map((contentDomain) => (
                                <Fragment
                                    key={contentDomain && contentDomain.id}
                                >
                                    <Definition
                                        label="NC CD Ref"
                                        value={
                                            contentDomain
                                                ? contentDomain.reference
                                                : ''
                                        }
                                    />
                                    {'strand' in learningObjective &&
                                        learningObjective.strand && (
                                            <Definition
                                                label="NC Strand"
                                                value={
                                                    learningObjective.strand
                                                        .title
                                                }
                                            />
                                        )}
                                    {'sub_strand' in learningObjective &&
                                        learningObjective.sub_strand && (
                                            <Definition
                                                label="NC Sub-strand"
                                                value={
                                                    learningObjective.sub_strand
                                                        .title
                                                }
                                            />
                                        )}
                                    <Definition
                                        label="NC Statement"
                                        value={
                                            contentDomain
                                                ? contentDomain.statement
                                                : ''
                                        }
                                    />
                                    <Divider sx={sx.divider} />
                                </Fragment>
                            ))
                        ) : (
                            <Typography gutterBottom>
                                There are no content domains associated with
                                this learning objective
                            </Typography>
                        )}
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default ContentDomainInfo;
